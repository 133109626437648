<template>
    <div>
        <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 所获荣誉 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
                </p>
            </div>
        </div>
        <div class="honor-con">
            <img src="../../../assets/images/honor.png">
        </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less">
  .honor-con{
      width: 1200px;
      margin:0 auto;
      img{
           width:100%;
    //   max-width:100%;
      object-fit:none;
      }
  }
</style>
